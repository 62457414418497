var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-content"},[_c('div',{staticStyle:{"display":"flex","flex-wrap":"wrap"}},_vm._l((_vm.grade),function(item,index){return _c('el-badge',{key:index,staticClass:"item",attrs:{"value":item.num,"max":99}},[_c('div',{staticClass:"circle",style:({
          backgroundColor:
            item.level === 3
              ? '#d9001b'
              : item.level === 2
              ? '#ffd83a'
              : item.level === 1
              ? '#70b603'
              : '',          
        }),on:{"click":function($event){return _vm.getJump(item.type,item.typeName)}}},[_c('div',{class:{'colorFFF':item.level == 1 || item.level == 2 || item.level == 3,'message':true }},[_vm._v(_vm._s(item.typeName))])])])}),1)])
}
var staticRenderFns = []

export { render, staticRenderFns }